<template>
  <v-container class="mb-5">
    <v-card tile flat color="white">
      <v-card-text class="primary lighten-5 px-10">
        <v-row v-if="!checkIfJobIsApplied()">
          <v-col cols="12" md="8">
            <h2 class="text-h3 primary--text">Interested in this Job</h2>
            <h4 class="text-h5">Apply today</h4>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="d-flex flex-row justify-end align-center"
          >
            <v-btn
              x-large
              :disabled="isEmpty()"
              color="primary"
              :to="{ name: 'Check', params: { code: singleJob.code } }"
            >
              Apply now
            </v-btn>
          </v-col>
        </v-row>
        <!-- check if job has been closed | expired -->
        <v-row
          align="center"
          justify="center"
          v-else-if="isJobApplicationClosed"
        >
          <v-col>
            <span class="red--text text-subtitle-2 font-italic caption pa-3"
              ><v-icon color="red" small>mdi-alert</v-icon>The job you are
              trying to apply for is closed.</span
            >
          </v-col>
        </v-row>

        <v-row align="center" justify="center" v-else>
          <v-col>
            <span class="red--text text-subtitle-2 font-italic caption pa-3"
              ><v-icon color="red" small>mdi-alert</v-icon>You have already
              applied to this position !</span
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="!isEmpty()">
      <v-card-title color="primary"
        ><span class="primary--text font-weight-black h2 ml-1">{{
          singleJob ? singleJob.fullTitle : "N/A"
        }}</span></v-card-title
      >
      <v-card-text>
        <v-row no-gutters class="caption">
          <v-col cols="12" md="6"
            ><span>Specialization(s) :</span>

            <span class="black--text font-italic font-weight-black ml-1">
              {{
                singleJob
                  ? singleJob?.vacancySpecializations
                      .map((d) => d.description)
                      .join(", ")
                  : ""
              }}
            </span></v-col
          >
          <v-col cols="12" md="6"
            >Location: <v-icon color="primary">mdi-map-marker</v-icon>
            <span class="black--text font-italic font-weight-black ml-1">
              {{ singleJob ? singleJob.location : "" }}
            </span></v-col
          >

          <!-- <v-col cols="12" md="6">Location: N/A</v-col> -->
          <v-col cols="12" md="6">
            Status:<span
              class="green--text font-italic font-weight-black caption ml-1"
              >{{ getStatus(singleJob.status) }}</span
            >
          </v-col>
          <v-col cols="12" md="6" v-if="singleJob.showPayRange === true"
            >Salary: KES
            <span
              class="green--text font-italic font-weight-black caption ml-1"
            >
              {{
                singleJob ? singleJob.minAskingSalary : "" | currencyFormat
              }}-{{
                singleJob ? singleJob.maxAskingSalary : "" | currencyFormat
              }}</span
            >

            / {{ singleJob ? singleJob.payFrequency : "" }}</v-col
          >
          <v-col cols="12" md="6" v-if="singleJob.showPayRange === false"
            >Salary:<span
              class="green--text font-italic font-weight-black caption ml-1"
              >Confidential</span
            >
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text v-if="singleJob.description !== ''">
        <!-- <h3 class="subtitle-1">Job description</h3> -->
        <h3 class="black--text font-subtitle-1 font-weight-medium">
          Job description
        </h3>

        <p class="caption text-justify">
          {{ singleJob ? singleJob.description : "" }}
        </p>
      </v-card-text>

      <v-card-text v-if="singleJob?.vacancyResponsibilities?.length !== 0">
        <!-- <h4 class="subtitle-2">Job Responsibilities</h4> -->
        <h4 class="black--text font-subtitle-1 font-weight-medium">
          Job Responsibilities
        </h4>
        <ul
          class="caption"
          v-for="resp in singleJob.vacancyResponsibilities"
          :key="resp.lineNo"
        >
          <li>{{ resp.description }}&nbsp;</li>
        </ul>
      </v-card-text>

      <v-card-text v-if="singleJob?.vacancyQualifications?.length !== 0">
        <!-- <h4 class="subtitle-2">Job Requirements</h4> -->
        <h4 class="black--text font-subtitle-1 font-weight-medium">
          Job Requirements
        </h4>

        <ul class="caption">
          <li v-for="req in singleJob.vacancyQualifications" :key="req.lineNo">
            {{ req.qualificationDescription }}
            <!-- <p>{{ req.qualificationCode }} - {{ req.minQualification }}</p> -->
          </li>
        </ul>
      </v-card-text>
      <v-card-text v-if="singleJob.vacancyAttributes.length !== 0">
        <!-- <h4 class="subtitle-2">Mandatory Documents</h4> -->
        <h4 class="black--text font-subtitle-1 font-weight-medium">
          Mandatory Documents
        </h4>

        <ul class="caption">
          <li v-for="doc in singleJob.mandatoryDocs" :key="doc.entryNo">
            <p>{{ doc.description }}</p>
          </li>
        </ul>
      </v-card-text>
      <v-card-text v-if="singleJob?.vacancyAttributes?.length !== 0">
        <!-- <h4 class="subtitle-2">Personal Attributes</h4> -->
        <h4 class="black--text font-subtitle-1 font-weight-medium">
          Personal Attributes
        </h4>

        <ul class="caption">
          <li v-for="i in singleJob.vacancyAttributes" :key="i.code">
            <p>{{ i.description }}</p>
          </li>
        </ul>
      </v-card-text>
      <v-card-text v-if="singleJob?.vacancyBenefits?.length !== 0" class="mt-n4">
        <h4 class="black--text font-subtitle-1 font-weight-medium">
          Job Benefits
        </h4>

        <ul class="caption">
          <li
            v-for="benefit in singleJob.vacancyBenefits"
            :key="benefit.lineNo"
          >
            {{ benefit.description }}
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <v-card v-if="isEmpty()">
      <v-skeleton-loader
        type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
      ></v-skeleton-loader
    ></v-card>
    <!-- job closed dialog -->
    <v-dialog v-model="jobClosedDialog" max-width="65%" persistent>
      <v-card>
        <v-card-title class="headline"
          >Job Application Closed/Expired</v-card-title
        >
        <v-card-text>
          The Job Vacancy was Invalid or has already been Closed. You will be
          redirected to the home page.
        </v-card-text>
        <v-card-actions>
          <v-btn @click="redirectToHome" color="primary">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end -->
  </v-container>
</template>

<script>
import AuthService from "@/modules/auth/authService";
export default {
  name: "jobCard",
  data: function () {
    return {
      departments: [],
      jobClosedDialog: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Home/getSingleJob", v.$route.params.code);
      if (AuthService.check()) {
        v.$store.dispatch("Home/getApplications", v.user.profileID);
      }
    });
  },
  mounted() {
    //console.log("closed", this.isJobApplicationClosed);
    Event.$on("show-closed-alert", () => {
      //this.$router.push({ name: "Home" });
      this.jobClosedDialog = true;
    });
  },
  computed: {
    singleJob: function () {
      return this.$store.getters["Home/homeGetters"]("singleJob");
    },

    routeParams() {
      console.log(this.$route.params.code);
      return this.$route.params.code;
    },
    user() {
      return AuthService.user;
    },
    applications: function () {
      return this.$store.getters["Home/homeGetters"]("applications");
    },
    isJobApplicationClosed() {
      if (!this.singleJob || !this.singleJob.status) {
        return true;
      }
      const jobStatus = this.singleJob.status.toLowerCase();
      const now = new Date();
      const deadlineDate = new Date(this.singleJob.deadlineDate);

      if (jobStatus === "closed" || now > deadlineDate) {
        return true; // fn Job()->closed
      } else {
        return false; // Job ()-> ongoing
      }
    },
  },

  methods: {
    isEmpty() {
      const obj = this.singleJob;
      return Object.keys(obj).length === 0;
    },
    getStatus(val) {
      if (val === "Open") {
        return "Ongoing";
      } else if (val === "Published") {
        return "Active";
      } else {
        return val;
      }
    },
    checkIfJobIsApplied: function () {
      if (
        this.applications.length !== 0 &&
        this.applications.find((d) => d.jobPosting === this.singleJob.code)
      ) {
        return true;
      } else {
        return false;
      }
    },

    redirectToHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped></style>
