export default {
  jobs: "vacancy",
  allJobs: "vacancy/all",
  categories: "category/jobCategories",
  submit: "job-applications",
  questions: "answers/save",
  SINGLEJOB: (id) => `vacancy/${id}`,
  APPLICATIONS: (id) => `job-applications/${id}`,
  SINGLEAPPLICATION: "job-applications/single-appl",
  jobOffer: "job-applications/job-offer",
  getJobOffer: (no) => `job-applications/job-offer?no=${no}`,
};
